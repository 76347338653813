//import Compressor from 'compressorjs';
//import { $EventBus } from '@/main';
import { mapActions, mapState } from 'vuex';
import SubTypeSelect from '@/components/incidents/elements/SubTypeSelect/SubTypeSelect.vue';

export default {
	name: 'AreaSelect',
	props: {
		value: {
			type: Array,
			required: false,
		},
		label: {
			type: String,
			required: false,
		},
	},
	data() {
		return {
			selectedArea: null,
			selectedClasification: null,
			requireRule: [(v) => !!v || 'Campo requerido'],
		};
	},
	created() {
		this.getAreas();
	},
	mounted() { },
	computed: {
		...mapState('area', ['allAreas']),

		// evidences: {
		// 	get() {
		// 		return this.value || false;
		// 	},
		// 	set(value) {
		// 		this.$emit('input', value);
		// 	},
		// },
		buttonText() {
			return this.label || this.defaultButtonText;
		},
	},
	watch: {},
	methods: {
		...mapActions('area', ['listAllAreas']),
		...mapActions('subtype', ['switchFlagObserveAsset', 'changeSelectedClassification']),

		async getAreas() {
			const { ok } = await this.listAllAreas({ onTree: true });
			if (!ok) {
				alert('No se pudo mostrara las áreas ');
			}
		},

		getClasification(area) {
			const classifications = area?.classifications;
			return Array.isArray(classifications) ? classifications : [];
		},

		getSubTypes(clasification) {
			const subTypes = clasification?.subtypes;
			return Array.isArray(subTypes) ? subTypes : [];
		},

		changeArea() {
			this.selectedClasification = null;
			this.changeSelectedClassification(null);
			this.switchFlagObserveAsset(false);
		},

		selectClassification() {
			this.changeSelectedClassification(this.selectedClasification);
		}
	},
	components: { SubTypeSelect },
};
