import { mapActions } from 'vuex';

export default {
	name: 'AssetBox',
	props: {
		userId: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
			isLoading: false,
			assets: [],
		};
	},
	computed: {},
	created() {
		this.getAssets();
	},
	watch: {
		userId: function () {
			this.getAssets();
		},
	},
	methods: {
		...mapActions('asset', ['getAssetByUser']),
		async getAssets() {
			this.isLoading = true;
			try {
				const data = {
					pagination: false,
					assigned_user: this.userId,
					company_id: parseInt(localStorage.getItem('company_id')),
				};
				const { ok, response } = await this.getAssetByUser(data);
				if (ok) {
					this.assets = response?.assets || [];
				}
			} catch (error) {
				const message = error?.data?.message;
				this.listError = Array.isArray(message) ? message.join(', ') : message;
			} finally {
				this.isLoading = false;
			}
		},
	},
};
