<template>
	<incident-create />
</template>
<script>
import IncidentCreate from '@/components/incidents/IncidentCreate/IncidentCreate.vue';

export default {
	name: 'IncidentCreatePage',
	components: { IncidentCreate },
};
</script>
